import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Card, Button, Container, Row, Col} from "react-bootstrap"
import Img from "gatsby-image"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query ProjectsComponentQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }, filter: {frontmatter: {type: {eq: "projects"}}}) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              link
              linkName
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.edges
  return (
    <div className='section-center'>
      <div className='cards-center'>
        {projects.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Link to={"/projects" + node.fields.slug} key={node.fields.slug} className='card'>
              <article>
                <Img className="card-img" fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.title} />
                <Card.Body className='card-content card-card'>
                    <h4>{title}</h4>
                    <hr />
                    <Card.Text>
                      {node.frontmatter.description}
                    </Card.Text>
                </Card.Body>
            </article>
            </Link>
          )
        })}
        
    </div>
    </div>
  )
}

export default Projects
